import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PadBox from "@bedrock-layout/padbox"
import Stack from "@bedrock-layout/stack"
import Center from "@bedrock-layout/center"
import styled from "styled-components"
import ap from "../images/ap.jpg"
import bellCanyon from "../images/bell-canyon.jpg"
import devConnector from "../images/dev-connector.jpg"
import guacamole from "../images/guacamole.jpg"
import hangman from "../images/hangman.jpg"
import drumkit from "../images/keyboard-drumkit.jpg"
import pixelArt from "../images/pixel-art.jpg"
import randomNumber from "../images/random-number-facts.jpg"
import randomPokemon from "../images/random-pokemon.jpg"

const projects = [
  {
    title: "My Personal Site",
    desc:
      "The site you're currently on! Built with React using Gatsby, and deployed with Netlify.",
    url: "http://arikparker.com",
    image: ap,
  },
  {
    title: "MERN Dev Connector",
    desc:
      "A simple social network site for developers. This was a nice intro to the MERN (MongoDB, Express, React, and Node) stack. Deployed with Heroku.",
    url: "https://pure-scrubland-34465.herokuapp.com/",
    image: devConnector,
  },
  {
    title: "Bell Canyon Property Inspections",
    desc:
      "A Utah-based property inspection company. This is a custom-built WordPress site built with Bootstrap 4.",
    url: "http://bellcanyonpropertyinspections.com/",
    image: bellCanyon,
  },
  {
    title: "Random Pokemon",
    desc:
      "Gotta catch 'em all! This fun little project is built with React and uses the PokeAPI to generate a random Pokemon when you click the animated Pokeball.",
    url: "https://codesandbox.io/s/random-pokemon-mrdgv",
    image: randomPokemon,
  },
  {
    title: "Hangman",
    desc:
      "You have a handful of guesses to correctly guess the mystery word. Make them count and keep smiling! Deployed with Netlify. Artwork created in Adobe Illustrator, UI built with Bootstrap, game powered by vanilla JS.",
    url: "https://aerawkhangman.netlify.app/",
    image: hangman,
  },
  {
    title: "Guac-A-Mole",
    desc:
      "You’re trying to whip up a few batches of your world-famous guacamole, but these avocados have other plans! Whack the avocados back into their bowls! Artwork created in Adobe Illustrator, game powered by vanilla JS.",
    url: "https://codepen.io/aerawk/full/bQrJRE",
    image: guacamole,
  },
  {
    title: "Pixel Art Maker",
    desc:
      "Time to bust out your happy little trees with this fun pixel art maker! Background art created with Adobe Illustrator, functionality powered by jQuery.",
    url: "https://codepen.io/aerawk/full/WzeoWv",
    image: pixelArt,
  },
  {
    title: "Keyboard Drum Kit",
    desc:
      "Want to drop some sweet beats? Check out this nifty keyboard drum kit! Powered by vanilla JS.",
    url: "https://codepen.io/aerawk/full/KRMGJG",
    image: drumkit,
  },
  {
    title: "Random Number Facts",
    desc:
      "Type in a number to learn a random fact about the corresponding year! UI built with Bootstrap, random facts accessed via the Numbers API.",
    url: "http://jsfiddle.net/aerawk/zoc8Lp4k/8/embedded/result/",
    image: randomNumber,
  },
]

const Title = styled.h1`
  color: #fff;
  text-align: center;
  text-shadow: 3px 3px 4px rgba(20, 20, 20, 0.4);
`

const FullPageBackground = styled(PadBox)`
  min-height: 100vh;
`

const PortfolioImage = styled.img`
  -webkit-box-shadow: 4px 4px 12px 3px rgba(51, 51, 51, 1);
  -moz-box-shadow: 4px 4px 12px 3px rgba(51, 51, 51, 1);
  box-shadow: 4px 4px 12px 3px rgba(51, 51, 51, 1);
  margin: 0 auto;
  max-width: 100%;
  border: 1px solid #fff;
  border-radius: 8px;
  transition: 400ms;
  &:hover {
    transition: 400ms;
    transform: scale(0.98);
    -webkit-box-shadow: 4px 4px 6px 0px rgba(51, 51, 51, 1);
    -moz-box-shadow: 4px 4px 6px 0px rgba(51, 51, 51, 1);
    box-shadow: 4px 4px 6px 0px rgba(51, 51, 51, 1);
  }
`

const ProjectList = () => {
  return (
    <>
      {projects.map(project => {
        const { title, desc, url, image } = project
        return (
          <PadBox
            padding={["xl"]}
            key={title}
            style={{
              color: "#fff",
              textAlign: "center",
              borderBottom: "1px solid #ed7723",
              // borderRadius: "16px",
              marginBottom: "24px",
            }}
          >
            <Center>
              <Stack>
                <h3>{title}</h3>
                <p>{desc}</p>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <PortfolioImage src={image} width="800" />
                </a>
                <br />
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#ed7723",
                    textDecoration: "none",
                    fontWeight: "bolder",
                  }}
                >
                  Check {title} out!
                </a>
              </Stack>
            </Center>
          </PadBox>
        )
      })}
    </>
  )
}

const Portfolio = () => (
  <Layout>
    <SEO title="Portfolio" />
    <FullPageBackground
      style={{ background: "#255b77" }}
      justify="center"
      padding={["xl"]}
    >
      <Center maxWidth={900}>
        <Title>Portfolio</Title>
        <PadBox style={{ color: "#fff" }}>
          How we look and behave when we meet someone for the first time leaves
          a lasting impression, and this can profoundly affect how much that
          person likes and trusts us. The same is certainly true of how a
          potential customer perceives a business. A prospective client’s first
          impression of your business is usually going to be with your website,
          so it’s crucial that this introduction is wonderful! I use the Adobe
          Creative Cloud to create responsive design ideas and mock-ups for your
          website to make sure that it looks good on screens of all sizes, and
          then bring them to life using modern technologies such as React,
          TypeScript, and styled-components, along with the latest in HTML, CSS,
          and JavaScript. And by using search engine optimization (SEO) best
          practices, I’ll work to make sure that potential customers see your
          website when searching for the goods and services your company
          provides. Whether you’re looking to update your existing website or
          you want a custom site built from the ground-up, I can help!
        </PadBox>
        <PadBox>
          <ProjectList />
        </PadBox>
      </Center>
    </FullPageBackground>
  </Layout>
)

export default Portfolio
